import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-staff__table editable_table flex flex-col"},[_c(VDataTable,{ref:"staffTableRef",staticClass:"c-table",attrs:{"single-select":"","item-key":"id","headers":_vm.headers,"items":_vm.tableDataArr,"items-per-page":_vm.itemsPerPage,"footer-props":_vm.footerProps,"data-cy":"staffTable"},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"click:row":_vm.rowClickHandler},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.updated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseDate(item.updated))+" ")]}},{key:"item.fileSize",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getHumanReadable(item.fileSize))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"c-staff__table__edit-record ml-2 absolute border-l border-grey-5",class:{ 'active-tr': item.active}},[(item.file)?_c('a',{staticClass:"mr-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.downloadHandler(item.id, _vm.endpoint)}}},[_c('fa-icon',{staticClass:"text-grey-6 hover:text-dark-blue",attrs:{"icon":"download"}})],1):_c('a',{staticClass:"mr-2",attrs:{"href":item.url,"target":"_blank"}},[_c('fa-icon',{staticClass:"text-grey-6 hover:text-dark-blue",attrs:{"icon":"external-link-alt"}})],1)])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }