<template>
    <div class="container flex flex-col">
        <div class="pt-3 pb-8">
            <h1 class="text-black text-xl lg:text-2xl mb-3" v-html="resourceTableName"></h1>
        </div>
        <div class="pb-8 w-1/6">
            <Search v-model="search" placeholder="Search all resources" />
        </div>
        <ResourceList :resources="filteredResources" :endpoint="'resource'" />
    </div>
</template>

<script>
import Search from '@/components/partials/Search'
import ResourceList from '@/components/resource-list/ResourceList'
import { AuthMixin } from '@/components/shared/mixins/authHelpers'
import camelcaseKeys from 'camelcase-keys'
import GET_RESOURCES from '@/graphql/queries/getResources.gql'
import RoleMixin from '@/components/shared/mixins/roleHelpers'

export default {
    name: 'ResourcesView',
    components: { Search, ResourceList },
    mixins: [ AuthMixin, RoleMixin ],
    data() {
        return {
            search: ''
        }
    },
    apollo: {
        resources: {
            query: GET_RESOURCES,
            debounce: 200,
            variables() {
                return {
                    search: `%${this.search}%`
                }
            },
            skip() {
                return !this.canViewResources
            },
            update({ resources }) {
                // TODO: need to add code to skip this stuff if user doesn't have
                this.loading = false
                return camelcaseKeys(resources, {deep: true})
            },
            watchLoading (isLoading, countModifier) {
                this.loading = isLoading
            },
            error(error, vm, key, type, options) {
                // for now, we just load it up with random data so we can see something
                this.error = error.message
            }
        },
    },
    computed: {
        resourceTableName() {
            let title = ''
            if (this.isAdmin) {
                title = 'All'
            } else {
                if (this.isSurveyor) {
                    title = 'Surveyor'
                }
                if (this.hasPracticeManagerRole) {
                    title = title !== '' ? `${title} &amp; Practice ` : 'Practice'
                }
            }
            return `${title} Resources`
        },
        filteredResources() {
            if (!this.resources || !this.resources.length) {
                return []
            }

            return this.resources.filter(resource => {
                if (this.isAdmin) return true
                
                if (this.isSurveyor && resource.forSurveyor) {
                    return true
                }
                if (this.hasPracticeManagerRole && resource.forPm) {
                    return true
                }

                return false
            })
        }
    },
    watch: {
        rolesLoaded(loaded) {
            if (loaded && !this.canViewResources) {
                this.$router.push('/Dashboard')
            }
        },
    }
}
</script>
