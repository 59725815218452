<template>
    <ResourcesTable :table-data="resources" :endpoint="endpoint" />
</template>

<script>
import ResourcesTable from '@/components/resource-list/partials/ResourcesTable'

export default {
    name: "ResourceList",
    components: { ResourcesTable },
    props: {
        resources: {
            type: Array,
            default: () => []
        },
        endpoint: {
            type: String,
            default: 'resource'
        }
    },
}
</script>
