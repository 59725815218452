<template>
    <div class="c-staff__table editable_table flex flex-col">
        <v-data-table
            ref="staffTableRef"
            single-select
            item-key="id"
            :headers="headers"
            :items="tableDataArr"
            :items-per-page.sync="itemsPerPage"
            :footer-props="footerProps"
            class="c-table"
            data-cy="staffTable"
            @click:row="rowClickHandler"
        >
            <template v-slot:[`item.name`]="{ item }">
                {{ item.name }}
            </template>
            <template  v-slot:[`item.updated`]="{ item }">
                {{ parseDate(item.updated) }}
            </template>
            <template v-slot:[`item.fileSize`]="{ item }">
                {{getHumanReadable(item.fileSize)}}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div class="c-staff__table__edit-record ml-2 absolute border-l border-grey-5" :class="{ 'active-tr': item.active}">
                    <a href="#" @click.prevent="downloadHandler(item.id, endpoint)" class="mr-2" v-if="item.file">
                        <fa-icon icon="download" class="text-grey-6 hover:text-dark-blue" />
                    </a>
                    <a :href="item.url" target="_blank" class="mr-2" v-else>
                        <fa-icon icon="external-link-alt" class="text-grey-6 hover:text-dark-blue" />
                    </a>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import MessageDialog from '@/components/shared/mixins/messageDialog'
import HttpMixin from '@/components/shared/mixins/httpMixin'
import { format } from "date-fns"
import FileMethods from '@/components/shared/mixins/fileMethods'
import HumanReadable from '@/utils/humanReadable'

export default {
    name: 'ResourcesTable',
    mixins: [MessageDialog, HttpMixin, FileMethods, HumanReadable],
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        filterOptions: {
            type: Array,
            default: () => []
        },
        endpoint: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            itemsPerPage: 20,
            footerProps: {
                'items-per-page-options': []
            },
            tableDataArr: [],
            headers: [
                { text: 'name', align: 'left', value: 'name' },
{ text: 'date', align: 'left', value: 'updated' },
                // { text: 'file size', align: 'left', value: 'fileSize' },
                { text: '', align: 'left', value: 'actions', fixed: true},
            ],
            currentRow: null,
        }
    },
    mounted() {
        this.tableDataArr = [...this.tableData]
    },
    methods: {
        rowClickHandler(obj, e) {
            console.log('row clicked handler', obj, e)
            this.currentRow = obj
            e.select(true)
        },
        parseDate(dateString) {
            return format(new Date(dateString), 'd LLL yyyy - h:mma')
        },
    },
    watch: {
        tableData() {
            this.tableDataArr = this.tableData
        },
    }
}
</script>
